<template>
  <v-main>
    <v-container>
      <v-row no-gutters class="logo-maria" align="center">
        <img src="@/assets/images/splash-talktomaria.png" alt="" style="width:200px">
      </v-row>
      <v-row no-gutters align="center"
      justify="center">
        <v-col v-show="!getLocation" align="center">
          Locating your nearest branch location ....
        </v-col>
        <v-col v-show="getLocation" align="center">
          <p>{{ message }}</p>
          <p><strong>{{ branchTitle }}</strong></p>
          <p>{{ destinationAddresses }}</p>
          <p><strong>{{ contact_no }}</strong></p>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { getNearestBranch } from '@/api/branchLocator'
export default {
  name: 'Home',
  data: function() {
      return{
          getLocation: false,
          message: '',
          branchTitle: '',
          destinationAddresses: '',
          contact_no: ''
      }
  },
  created(){
        navigator.geolocation.getCurrentPosition(
            position => {
              getNearestBranch({latitude: position.coords.latitude, longitude: position.coords.longitude}).then(response => {
                var data = response.data.data[0]
                this.branchTitle = data.branch_name + " Branch"
                this.destinationAddresses = data.bldg_street + ", " + data.barangay + ", " + data.city + ", " + data.province
                this.contact_no = data.contact_no_1

       

                this.getLocation = true
              })
            },
            error => {
              console.log(error.message);
            },
        )   
    }
}
</script>

<style scoped>
.logo-maria{
    margin-top: 100px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
</style>
